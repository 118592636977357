// Composables
import { createRouter, createWebHistory } from 'vue-router'

import multiguard from 'vue-router-multiguard';

import {
  viewerMode,
  managerMode,
  logisticsMode,
  showConstructibleList,
  showDesignsList,
  onlyManagersGuard,
  // onlyAdminsGuard,
  hasSessionObjectGuard,
  isAuthenticated,
  hasModuleForecast,
  hasModuleProduction,
  prevConstHasRCPRunning,
  asyncStoreDispatcher,
  afterEachGuard,
}
  from './guards'
import { LEGACY_REDIRECTIONS } from './legacy_redirections'



const routes = [
  // LEGACY REDIRECTIONS
  ...LEGACY_REDIRECTIONS,

  // GENERAL ROUTES
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/misc/Landing.vue'),
    beforeEnter: multiguard([isAuthenticated]),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/misc/Login.vue'),
    props: true,
  },
  {
    path: '/qr',
    name: 'qr',
    component: () => import('@/views/misc/QRReader.vue'),
  },
  {
    path: "/account",
    name: "account",
    component: () => import('@/views/misc/AccountView.vue'),
    beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, hasModuleProduction])
  },

  // EXTERNAL ROUTES
  {
    path: '/external/:company_uuid/:project_number/:wall_name',
    name: 'external',
    component: () => import('@/views/misc/ExternalURLHandler.vue'),
    beforeEnter: multiguard([isAuthenticated]),
    props: true,
  },






  // VIEWER ROUTES
  {
    path: '/viewer/project/:project_uuid',
    name: 'project_viewer',
    component: () => import('@/views/viewer/ProjectViewer.vue'),
    beforeEnter: multiguard([isAuthenticated, viewerMode, hasSessionObjectGuard, hasModuleProduction])
  },
  {
    path: '/viewer',
    name: 'viewer',
    component: () => import('@/views/viewer/Viewer.vue'),
    beforeEnter: multiguard([isAuthenticated, viewerMode, hasSessionObjectGuard, hasModuleProduction])
  },
  {
    path: '/viewer/assembly_design/:assd_uuid',
    name: 'assd_viewer',
    component: () => import('@/views/viewer/ConstructibleViewer.vue'),
    beforeEnter: multiguard([isAuthenticated, viewerMode, showDesignsList, hasSessionObjectGuard, hasModuleProduction])
  },
  {
    path: '/viewer/assembly/:ass_uuid',
    name: 'ass_viewer',
    meta: { dont_leave_if_rcp_running: true },
    component: () => import('@/views/viewer/ConstructibleViewer.vue'),
    beforeEnter: multiguard([isAuthenticated, viewerMode, showConstructibleList, hasSessionObjectGuard, hasModuleProduction])
  },
  {
    path: '/viewer/module_design/:modd_uuid',
    name: 'modd_viewer',
    component: () => import('@/views/viewer/ConstructibleViewer.vue'),
    beforeEnter: multiguard([isAuthenticated, viewerMode, showDesignsList, hasSessionObjectGuard, hasModuleProduction])
  },
  {
    path: '/viewer/module/:mod_uuid',
    name: 'mod_viewer',
    meta: { dont_leave_if_rcp_running: true },
    component: () => import('@/views/viewer/ConstructibleViewer.vue'),
    beforeEnter: multiguard([isAuthenticated, viewerMode, showConstructibleList, hasSessionObjectGuard, hasModuleProduction])
  },

  // PROJECT SPECIFIC ROUTES
  {
    path: '/projects/:project_uuid/constructibles',
    name: 'constructibles',
    component: () => import('@/views/manager/Constructibles.vue'),
    beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
  },
  {
    path: '/projects/:project_uuid/constructibles/assembly/:ass_uuid',
    name: 'assembly_detail',
    component: () => import('@/views/manager/Constructibles.vue'),
    beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
  },
  {
    path: '/projects/:project_uuid/constructibles/module/:mod_uuid',
    name: 'module_detail',
    component: () => import('@/views/manager/Constructibles.vue'),
    beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
  },
  {
    path: '/projects/:project_uuid/files',
    name: 'files',
    component: () => import('@/views/manager/FilesView.vue'),
    beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
  },
  {
    path: '/projects/:project_uuid/designs',
    name: 'designs',
    component: () => import('@/views/manager/Designs.vue'),
    beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
  },
  {
    path: '/projects/:project_uuid/designs/assembly/:assd_uuid',
    name: 'assembly_design_detail',
    component: () => import('@/views/manager/Designs.vue'),
    beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
  },
  {
    path: '/projects/:project_uuid/designs/module/:modd_uuid',
    name: 'module_design_detail',
    component: () => import('@/views/manager/Designs.vue'),
    beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
  },
  {
    path: '/projects/:project_uuid/designs/:design_uuid/parts',
    name: 'design_parts',
    component: () => import('@/views/manager/DesignParts.vue'),
    beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
  },
  {
    path: '/projects/:project_uuid/overview',
    name: 'project_overview',
    component: () => import('@/views/manager/ProductionOverview.vue'),
    beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, hasModuleForecast, managerMode])
  },
  {
    path: '/projects/:project_uuid/issues',
    name: 'issues',
    component: () => import('@/views/manager/Issues.vue'),
    beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
  },
  {
    path: '/projects/:project_uuid/issues/:issue_uuid',
    name: 'issue_detail',
    component: () => import('@/views/manager/Issues.vue'),
    beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
  },
  {
    path: '/projects/:project_uuid/settings',
    name: 'project_settings',
    component: () => import('@/views/manager/ProjectSettings.vue'),
    beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
  },
  {
    path: '/projects/:project_uuid/quantities',
    name: 'quantities',
    component: () => import('@/views/manager/ConstructibleQuantities.vue'),
    beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
  },
  {
    path: '/projects/:project_uuid/reports',
    name: 'reports',
    component: () => import('@/views/manager/ReportManager.vue'),
    beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
  },
  {
    path: '/projects/:project_uuid/logistics',
    name: 'logistics',
    component: () => import('@/views/logistics/Logistics.vue'),
    beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, logisticsMode])
  },


  // COMPANY WIDE ROUTES
  {
    path: '/company/constructibles_manager',
    name: 'constructibles_manager',
    component: () => import('@/views/manager/ConstructiblesListManager.vue'),
    beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
  },
  {
    path: '/company/constructibles_manager/assembly/:ass_uuid',
    name: 'constructibles_manager_assembly_detail',
    component: () => import('@/views/manager/ConstructiblesListManager.vue'),
    beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
  },
  {
    path: '/company/constructibles_manager/module/:mod_uuid',
    name: 'constructibles_manager_module_detail',
    component: () => import('@/views/manager/ConstructiblesListManager.vue'),
    beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
  },
  {
    path: '/company/constructibles_manager/assembly_design/:assd_uuid',
    name: 'constructibles_manager_assembly_design_detail',
    component: () => import('@/views/manager/ConstructiblesListManager.vue'),
    beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
  },
  {
    path: '/company/constructibles_manager/module_design/:modd_uuid',
    name: 'constructibles_manager_module_design_detail',
    component: () => import('@/views/manager/ConstructiblesListManager.vue'),
    beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
  },
  {
    path: '/company/parts',
    name: 'parts_list',
    component: () => import('@/views/manager/PartsList.vue'),
    beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
  },

  {
    path: '/company/delivery',
    name: 'delivery',
    component: () => import('@/views/manager/Delivery.vue'),
    beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
  },

  {
    path: '/company/stock_products',
    name: 'stock_products',
    component: () => import('@/views/manager/StockProducts.vue'),
    beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
  },
  {
    path: '/company/settings',
    name: 'company_settings',
    component: () => import('@/views/manager/Company.vue'),
    beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
  },
  {
    path: '/company/reports',
    name: 'company_reports',
    component: () => import('@/views/manager/ReportManager.vue'),
    beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
  },
  {
    path: '/company/logistics',
    name: 'company_logistics',
    component: () => import('@/views/logistics/Logistics.vue'),
    beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, logisticsMode])
  },
  {
    path: '/company/production_overview',
    name: 'production_overview',
    component: () => import('@/views/manager/ProductionOverview.vue'),
    beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, hasModuleForecast, managerMode])
  },
  {
    path: "/orders",
    name: 'orders',
    component: () => import('@/views/manager/OrdersView.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'redirect',
    redirect: '/'
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]


const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory('/app/'),
  routes,
})



router.beforeEach(prevConstHasRCPRunning)

router.beforeEach(asyncStoreDispatcher)

router.afterEach(afterEachGuard)

export default router
