import base from "./base"
import { defineStore } from 'pinia'

export const useLogisticsStore = defineStore('logistics', {
  state: () => ({
    materials: [],
    product_plates: [],
    product_profiles: [],
    plate_sizes: [],
    profile_sizes: [],
    racks: [],
  }),
  getters: {
    // No getters
  },
  actions: {
    // Materials
    setMaterials: base.actions.setter("materials"),
    createMaterial: base.actions.creator("materials"),
    updateMaterial: base.actions.updater("materials"),
    removeMaterial: base.actions.remover("materials"),

    // Product Plates
    setProductPlates: base.actions.setter("product_plates"),
    createProductPlate: base.actions.creator("product_plates"),
    updateProductPlate: base.actions.updater("product_plates"),
    removeProductPlate: base.actions.remover("product_plates"),

    // Product Profiles
    setProductProfiles: base.actions.setter("product_profiles"),
    createProductProfile: base.actions.creator("product_profiles"),
    updateProductProfile: base.actions.updater("product_profiles"),
    removeProductProfile: base.actions.remover("product_profiles"),

    // Plate Sizes
    setPlateSizes: base.actions.setter("plate_sizes"),
    createPlateSize: base.actions.creator("plate_sizes"),
    updatePlateSize: base.actions.updater("plate_sizes"),
    removePlateSize: base.actions.remover("plate_sizes"),

    // Profile Sizes
    setProfileSizes: base.actions.setter("profile_sizes"),
    createProfileSize: base.actions.creator("profile_sizes"),
    updateProfileSize: base.actions.updater("profile_sizes"),
    removeProfileSize: base.actions.remover("profile_sizes"),

    // Racks
    setRacks: base.actions.setter("racks"),
    createRack: base.actions.creator("racks"),
    updateRack: base.actions.updater("racks"),
    removeRack: base.actions.remover("racks"),

    // Materials
    loadMaterials: base.actions.loader("logistics/materials/", "Materials"),
    postMaterial: base.actions.poster("logistics/materials/", "Material"),
    putMaterial: base.actions.putter("logistics/materials/", "Material"),
    deleteMaterial: base.actions.deleter("logistics/materials/", "Material"),

    // Product Plates
    loadProductPlates: base.actions.loader("logistics/products/plates/", "ProductPlates"),
    postProductPlate: base.actions.poster("logistics/products/plates/", "ProductPlate"),
    putProductPlate: base.actions.putter("logistics/products/plates/", "ProductPlate"),
    deleteProductPlate: base.actions.deleter("logistics/products/plates/", "ProductPlate"),

    // Product Profiles
    loadProductProfiles: base.actions.loader("logistics/products/profiles/", "ProductProfiles"),
    postProductProfile: base.actions.poster("logistics/products/profiles/", "ProductProfile"),
    putProductProfile: base.actions.putter("logistics/products/profiles/", "ProductProfile"),
    deleteProductProfile: base.actions.deleter("logistics/products/profiles/", "ProductProfile"),

    // Plate Sizes
    loadPlateSizes: base.actions.loader("logistics/sizes/plates/", "PlateSizes"),
    postPlateSize: base.actions.poster("logistics/sizes/plates/", "PlateSize"),
    putPlateSize: base.actions.putter("logistics/sizes/plates/", "PlateSize"),
    deletePlateSize: base.actions.deleter("logistics/sizes/plates/", "PlateSize"),

    // Profile Sizes
    loadProfileSizes: base.actions.loader("logistics/sizes/profiles/", "ProfileSizes"),
    postProfileSize: base.actions.poster("logistics/sizes/profiles/", "ProfileSize"),
    putProfileSize: base.actions.putter("logistics/sizes/profiles/", "ProfileSize"),
    deleteProfileSize: base.actions.deleter("logistics/sizes/profiles/", "ProfileSize"),

    // Racks
    loadRacks: base.actions.loader("logistics/delivery/racks/", "Racks"),
    postRack: base.actions.poster("logistics/delivery/racks/", "Rack"),
    putRack: base.actions.putter("logistics/delivery/racks/", "Rack"),
    deleteRack: base.actions.deleter("logistics/delivery/racks/", "Rack"),
  },
})
