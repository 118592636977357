<template>
    <div class="d-flex flex-column">

        <div v-if="rail" class="mt-12">
            <v-list color="accent" nav>
                <ButtonUser listitem />
                <v-list-item :disabled="!m_prod_management" to="/qr">
                    <template v-slot:prepend>
                        <v-icon> mdi-qrcode-scan </v-icon>
                    </template>
                    <v-list-item-title>
                        qr
                    </v-list-item-title>
                </v-list-item>
                <ButtonLanguage listitem v-if="user.auth.multilang" />
                <ButtonChat listitem />
            </v-list>
        </div>

        <v-sheet v-else class="sticky-header">
            <div class="d-flex justify-space-around ml-10">
                <ButtonLanguage v-if="user.auth.multilang" icon />
                <div class="logo-container text-center mb-3">
                    <v-img v-if="company_logo" transition="scale-transition" max-width="130" max-height="50" contain
                        :class="dark ? 'inverted' : ''" :src="company_logo"></v-img>
                    <v-img class="mt-2 mx-auto" v-else-if="dark" transition="scale-transition" max-width="130" contain
                        src="https://woodwall-public.s3.eu-central-1.amazonaws.com/img/pit_logo_dark.svg"></v-img>
                    <v-img class="mt-2 mx-auto" v-else transition="scale-transition" max-width="130" contain
                        src="https://woodwall-public.s3.eu-central-1.amazonaws.com/img/pit_logo.svg">
                    </v-img>
                </div>
                <v-btn to="/qr" icon="mdi-qrcode-scan" class="ml-2" variant="text">
                </v-btn>
                <ButtonChat />
            </div>


            <div class="d-flex flex-row">
                <div>
                    <ButtonUser />
                </div>
                <div class="ml-1">
                    <SelectorProductionDate v-if="show_by_date_worker" />
                    <SelectorProject v-else />
                </div>
            </div>
            <div class="d-flex flex-row" v-if="manager_or_admin">
                <ButtonMode />
            </div>
            <MenuHeaderViewerButtons v-if="viewer_mode && !rail" />

        </v-sheet>

        <div v-if="viewer_mode && !rail">
            <ListViewerGroup />
        </div>

        <div v-if="company_wide && manager_or_admin && !viewer_mode && !logistics_mode">
            <v-list color="accent" nav>
                <v-list-item disabled :to="'/company/production_overview'">
                    <!-- <v-list-item 
                :disabled="!m_prod_management" 
                :to="'/company/production_overview'"> -->
                    <template v-slot:prepend>
                        <v-icon> mdi-chart-gantt </v-icon>
                    </template>
                    <v-list-item-title>
                        {{ t("production_overview") }}
                    </v-list-item-title>
                </v-list-item>
                <v-list-item :disabled="!m_bom" :to="'/company/reports'">
                    <template v-slot:prepend>
                        <v-icon> mdi-file-document-outline </v-icon>
                    </template>
                    <v-list-item-title>
                        {{ t("reports") }}
                    </v-list-item-title>
                </v-list-item>

                <v-list-item :to="'/company/stock_products'">
                    <template v-slot:prepend>
                        <v-icon> mdi-chart-bar </v-icon>
                    </template>
                    <v-list-item-title>
                        {{ t("stock_products") }}
                    </v-list-item-title>
                </v-list-item>

                <v-list-item :to="'/company/constructibles_manager'">
                    <template v-slot:prepend>
                        <v-icon> mdi-garage-variant </v-icon>
                    </template>
                    <v-list-item-title>
                        {{ t("constructibles_list") }}
                    </v-list-item-title>
                </v-list-item>

                <v-list-item v-if="i_am_staff" :to="'/company/parts'">
                    <template v-slot:prepend>
                        <v-icon> mdi-lightbulb-fluorescent-tube-outline </v-icon>
                    </template>
                    <v-list-item-title>
                        {{ t("parts_list") }} <v-chip size="small" class="ml-2" variant="outlined" color="accent"> beta </v-chip>
                    </v-list-item-title>
                </v-list-item>


                <v-list-item v-if="i_am_staff" :to="'/company/delivery'">
                    <template v-slot:prepend>
                        <v-icon> mdi-truck-delivery-outline </v-icon>
                    </template>
                    <v-list-item-title>
                        {{ t("Delivery") }} <v-chip size="small" class="ml-2" variant="outlined" color="accent"> beta </v-chip>
                    </v-list-item-title>
                </v-list-item>

                <v-list-item :to="'/company/settings'">
                    <template v-slot:prepend>
                        <v-icon> mdi-cog </v-icon>
                    </template>
                    <v-list-item-title>
                        {{ t("company_settings") }}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </div>

        <div v-if="logistics_mode && !rail">
            <v-container>
                <v-row dense>
                    <v-col>
                        <SelectTreeLogisticsByDate v-if="work_by_date && company_wide" />
                        <SelectTreeLogistics v-else />
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div v-if="!company_wide && manager_or_admin && !viewer_mode && !logistics_mode">
            <v-list color="accent" nav>
                <v-list-item v-if="!stdlib_mode" disabled :to="'/projects/' + selected_project_uuid + '/overview'">
                    <template v-slot:prepend>
                        <v-icon> mdi-chart-gantt </v-icon>
                    </template>
                    <v-list-item-title>
                        {{ t("project_overview") }}
                    </v-list-item-title>
                </v-list-item>

                <v-list-item :to="'/projects/' + selected_project_uuid + '/files'">
                    <template v-slot:prepend>
                        <v-icon> mdi-file-cog-outline </v-icon>
                    </template>
                    <v-list-item-title>
                        {{ t("files_and_operations") }}
                    </v-list-item-title>
                </v-list-item>

                <v-list-item :to="'/projects/' + selected_project_uuid + '/designs'">
                    <template v-slot:prepend>
                        <v-icon> mdi-text-box-multiple-outline </v-icon>
                    </template>
                    <v-list-item-title>
                        {{ t("designs") }}
                    </v-list-item-title>
                </v-list-item>


                <v-list-item v-if="!stdlib_mode" :to="'/projects/' + selected_project_uuid + '/quantities'">
                    <template v-slot:prepend>
                        <v-icon> mdi-dots-grid </v-icon>
                    </template>
                    <v-list-item-title>
                        {{ t("production_counts") }}
                    </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="!stdlib_mode" :to="'/projects/' + selected_project_uuid + '/constructibles'">
                    <template v-slot:prepend>
                        <v-icon> mdi-garage-variant </v-icon>
                    </template>
                    <v-list-item-title>
                        {{ t("constructibles_list") }}
                    </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="!stdlib_mode" :to="'/projects/' + selected_project_uuid + '/issues'">
                    <template v-slot:prepend>
                        <v-icon> mdi-garage-alert-variant </v-icon>
                    </template>
                    <v-list-item-title>
                        {{ t("production_issues") }}
                    </v-list-item-title>
                </v-list-item>
                <v-list-item :disabled="!m_bom" :to="'/projects/' + selected_project_uuid + '/reports'">
                    <template v-slot:prepend>
                        <v-icon> mdi-file-document-outline </v-icon>
                    </template>
                    <v-list-item-title>
                        {{ t("reports") }}
                    </v-list-item-title>
                </v-list-item>
                <v-list-item :to="'/projects/' + selected_project_uuid + '/settings'">
                    <template v-slot:prepend>
                        <v-icon> mdi-folder-cog-outline </v-icon>
                    </template>
                    <v-list-item-title>
                        {{ t("project_settings") }}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </div>
        <div class="pa-10">

        </div>
        <div v-if="company_logo && !rail" class="logo-container-bottom white-gradient-color d-flex"
            :class="dark ? 'dark' : ''">
            <v-img v-if="dark" transition="scale-transition" max-width="130" contain class="mx-auto mt-5 dark"
                src="https://woodwall-public.s3.eu-central-1.amazonaws.com/img/pit_logo_dark.svg"></v-img>
            <v-img v-else transition="scale-transition" max-width="130" contain class="mx-auto mt-5"
                src="https://woodwall-public.s3.eu-central-1.amazonaws.com/img/pit_logo.svg"></v-img>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.sticky-header {
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 16px;
    padding-bottom: 8px;
}



.dark {
    -webkit-filter: invert(1);
    filter: invert(1);
}

.inverted {
    filter: invert(64%) contrast(228%) brightness(80%) hue-rotate(180deg);
}

.logo-container {
    width: 130px;
}

.white-background {
    background-color: lightslategrey;
}

.logo-container-bottom {
    position: fixed;
    left: 0;
    bottom: 0;
    height: 80px;
    width: 100%;
    text-align: center;
}



.white-gradient-color {
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg,
            rgba(255, 255, 255, 1) 30%,
            rgba(255, 255, 255, 0) 100%);
}
</style>

<script>
import ButtonLanguage from "/src/components/account/ButtonLanguage";
import ButtonChat from "./ButtonChat";
import ButtonUser from "./ButtonUser";
import ListViewerGroup from "./list/ListViewerGroup.vue";
import MenuHeaderViewerButtons from "./list/MenuHeaderViewerButtons.vue";
import ButtonMode from "./ButtonMode";
import SelectorProject from "./SelectorProject";
import SelectorProductionDate from "./SelectorProductionDate";
import SelectTreeLogistics from "./SelectTreeLogistics.vue";
import { useAppStore } from "@/stores/app";
import { useCloudstorageStore } from "@/stores/cloudstorage";
import { useCompanyStore } from "@/stores/company";
import { useSelectedStore } from "@/stores/selected";
import { useSessionStore } from "@/stores/session";
import { useTranslationsStore } from "@/stores/translations";
import { useViewerStore } from "@/stores/viewer";
import SelectTreeLogisticsByDate from "./SelectTreeLogisticsByDate.vue";



export default {
    setup() {
        const app_store = useAppStore()
        const cloudstorage_store = useCloudstorageStore()
        const company_store = useCompanyStore()
        const selected_store = useSelectedStore()
        const session_store = useSessionStore()
        const translations_store = useTranslationsStore()
        const viewer_store = useViewerStore()

        const t = translations_store.translate

        return {
            app_store,
            cloudstorage_store,
            company_store,
            selected_store,
            session_store,
            t,
            viewer_store,
        }
    },
    components: {
        ButtonLanguage,
        ButtonChat,
        ButtonUser,
        ListViewerGroup,
        MenuHeaderViewerButtons,
        ButtonMode,
        SelectorProject,
        SelectorProductionDate,
        SelectTreeLogistics,
        SelectTreeLogisticsByDate,
    },
    props: ["rail"],
    watch: {
        user(u) {
            if (u?.auth?.hide_panel_queue)
                this.viewer_store.setDesignsMode(true);
            else this.viewer_store.setDesignsMode(false);
        },
        manager_or_admin(moa) {
            if (!moa) {
                this.app_store.setViewerMode();
            }
        },
    },
    computed: {
        dark() {
            return this.$vuetify.theme.global.current.dark
        },
        user() {
            return this.session_store.user_object;
        },
        loading() {
            return this.selected_store.viewer_loading;
        },
        viewer_mode() {
            return this.app_store.app_mode == "production";
        },
        logistics_mode() {
            return this.app_store.app_mode == "logistics";
        },
        stdlib_mode() {
            return this.selected_store.stdlib_mode
        },
        manager_or_admin() {
            return !this.session_store.iam_prod_worker;
        },
        i_am_staff() {
            return this.session_store.user_object?.auth?.pit_staff;
        },
        show_by_date_worker() {
            if (this.manager_or_admin) return false
            return this.company?.work_by_date || false
        },
        projects() {
            return this.company_store.projects;
        },
        company_wide() {
            return this.selected_project_uuid == "all";
        },
        selected_project_uuid() {
            return this.selected_store.selected_project?.uuid || "";
        },
        selected_project() {
            return this.selected_store.selected_project || "";
        },
        company() {
            return this.session_store.user_object?.auth?.company;
        },
        company_pck() {
            return this.cloudstorage_store.packagesByNameAndLevel({
                name: "resources",
                level: "company",
            })?.[0]
        },
        company_logo() { return this.company_pck?.files?.find(cf => cf.meta.section == "logo")?.presigned_url },
        m_prod_management() {
            return this.company?.m_prod_management || false;
        },
        m_bom() {
            return this.company?.m_bom || false;
        },
        work_by_date() {
            return this.company?.work_by_date
        },
    },
};
</script>
